import React from 'react';

import { EMPLOYMENT_APPLICATION_LINK } from '../../../utils';
import { Banner, SiteButton } from '../../common/index';

import { baseBreadcrumbs } from '../index';
import './Employment.css';


export const Employment = () => {

    const renderJobOpening = (label: string) => {
        return (
            <li className="employment-job-opening">{label}</li>
        )
    }

    return (
        <div id="employment">
            <Banner title='Employment' breadcrumbs={[...baseBreadcrumbs, { name: 'Employment', path: '/contact_us/employment' }]}/>
            <div className="text-container">
                <h3>Employment</h3>
                <p>We are often seeking additional therapists to hire as MP Health continues to grow. If you are interested in working in a supportive environment with a flexible schedule and competitive rates, then please contact us immediately and submit your resume and a cover letter to <a href={`mailto:info@mariposasproject.com`}>info@mariposasproject.com</a>.</p>
                <hr />
                <h3>Current Job Openings</h3>
                <ul>
                    {renderJobOpening("Bilingual Speech and Language Pathologist")}
                </ul>
                <SiteButton label="Employment Form" url={EMPLOYMENT_APPLICATION_LINK} />
            </div>
        </div>
    )
}