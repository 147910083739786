import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'antd';

import { Banner } from '../../common/Banner/Banner';

import { baseBreadcrumbs } from '../index';

import moreThanWords from '../../../static/img/other-programs/More-Than-Words-Logo.jpeg';
import './MoreThanWords.css';

export const MoreThanWords = () => {
    
    return (
        <div id="more-than-words">
            <Banner title={"More Than Words"} breadcrumbs={[...baseBreadcrumbs, { name: "More Than Words", path: "/other_programs/more_than_words" }]}/>
            <div className="text-container">
                <h2>Who is the best fit for each program?</h2>
                <p>More Than Words (MTW) is designed specifically for parents of children ages 5 and under on the autism spectrum and with other social communication difficulties.</p>
            </div>
            <div className="image-container">
                <Image src={moreThanWords}/>
            </div>
            <div className="text-container">
                <h2>Program Components</h2>
                <ul>
                    <li>7 small-group parent training sessions in small, personalized groups</li>
                    <li>A Hanen-certified speech-language pathologist leading the program</li>
                    <li>3 individual visits provided by the speech-language pathologist in which the parent is videotaped using Hanen strategies. The parent and speech-language pathologist will watch the videotaped interaction to see what&apos;s helping the child and what could be modified to help the child even more.</li>
                </ul>
                <h2>Benefits of the Programs</h2>
                <ul>
                    <li>Practical strategies to use in everyday situations</li>
                    <li>An effective, evidence-based and reputable program that is well worth the time</li>
                    <li>A chance to connect with other parents</li>
                </ul>
            </div>
        </div>
    )
}