import React, { useMemo } from 'react';
import { Row, Col } from 'antd';

import { ADMISSION_INTAKE_AND_CONSENT_FORM_URL, ADMISSION_INTAKE_AND_CONSENT_FORM_ES_URL } from '../../utils/index';
import { Banner, SiteButton } from '../common/index';

import { baseBreadcrumbs } from './index';
import './StartChildTherapy.css';

export const StartChildTherapy = () => {

    return (
        <div id="start-child-therapy">
            <Banner title='Start Child Therapy' breadcrumbs={baseBreadcrumbs}/>
            <div className="text-container">
                <h2>Where to Start:</h2>
                <ol>
                    <li>Please obtain a prescription from your child&apos;s pediatrician.</li>
                    <li>Contact your insurance company to understand your insurance benefits.</li>
                    <li>Contact MP Health to complete referral/intake information.</li>
                </ol>
                <p>Once we have all your information, our provider will be contacting you to set up an evaluation with therapy services.</p>
                <hr />
                <div className="form-site-buttons-container">
                    <Row gutter={16}>
                        <Col>
                            <SiteButton label='Admission Intake and Consent Form' url={ADMISSION_INTAKE_AND_CONSENT_FORM_URL} />
                        </Col>
                        <Col>
                            <SiteButton label='Formulario de Admision y Consentimiento' url={ADMISSION_INTAKE_AND_CONSENT_FORM_ES_URL} />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}