import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'antd';

import { Banner } from '../../common/Banner/Banner';

import { baseBreadcrumbs } from '../index';

import itTakesTwoToTalk from '../../../static/img/other-programs/ItTakesTwotoTalkLogo.jpeg';
import './ItTakesTwoToTalk.css';

export const ItTakesTwoToTalk = () => {
    
    return (
        <div id="it-takes-two-to-talk">
            <Banner title={"It Takes Two to Talk"} breadcrumbs={[...baseBreadcrumbs, { name: "It Takes Two to Talk", path: "/other_programs/it_takes_two_to_talk" }]}/>
            <div className="image-container">
                <Image src={itTakesTwoToTalk} />
            </div>
            <div className="text-container">
                <p>It Takes Two to Talk (ITTT) is designed specifically for parents of young children birth to age 5 who have been identified as having a language delay.</p>
                <h2>Program Components</h2>
                <ul>
                    <li>7 small-group parent training sessions in small, personalized groups</li>
                    <li>A Hanen-certified speech-language pathologist leading the program</li>
                    <li>3 individual visits provided by the speech-language pathologist in which the parent is videotaped using Hanen strategies. The parent and speech-language pathologist will watch the videotaped interaction to see what&apos;s helping the child and what could be modified to help the child even more.</li>
                </ul>
                <h2>Benefits of the Programs</h2>
                <ul>
                    <li>Practical strategies to use in everyday situations</li>
                    <li>An effective, evidence-based and reputable program that is well worth the time</li>
                    <li>A chance to connect with other parents</li>
                </ul>
            </div>
        </div>
    )
}