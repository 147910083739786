import React from 'react';

import { Banner } from '../../common/index';

import { baseBreadcrumbs } from '../index';
import './Counseling.css';

export const Counseling = () => {
    return (
        <div id="counseling">
            <Banner title='Counseling' breadcrumbs={[...baseBreadcrumbs, { name: 'Counseling', path: '/therapy_services/trauma_and_attachment_support_group'}]}/>
            <div className="text-container">
                <p>MP Health Counseling offers a variety of mental health services to individuals, couples and children. We also offer parent groups for children diagnosed with Autism Spectrum Disorder. We address numerous issues such as anxiety, depression, grief and loss, PTSD, attachment disorders and substance abuse. Please call our office if you have any questions regarding counseling services (We offer bilingual services).</p>
            </div>
        </div>
    )
}