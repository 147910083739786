import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'antd';

import { Banner } from '../../common/Banner/Banner';

import { baseBreadcrumbs } from '../index';
import './SantaBarbara.css';
import santaBarbara from '../../../static/img/locations/SantaBarbara.jpeg';

export const SantaBarbara = () => {
    
    return (
        <div id="santa-barbara">
            <Banner title={"Santa Barbara"} breadcrumbs={[...baseBreadcrumbs, { name: "Santa Barbara", path: "/santa_barbara" }]}/>
            <div className="image-container">
                <Image src={santaBarbara}/>
            </div>
        </div>
    )
}