import React from 'react';

import { Banner } from '../../common/index';

import { baseBreadcrumbs } from '../index';
import './TraumaAndAttachmentSupportGroup.css';

export const TraumaAndAttachmentSupportGroup = () => {
    return (
        <div id="trauma-and-attachment-support-group">
            <Banner title='Trauma and Attachment Support Group' breadcrumbs={[...baseBreadcrumbs, { name: 'Trauma and Attachment Support Group', path: '/therapy_services/trauma_and_attachment_support_group'}]}/>
            <div className="text-container">
                <p>The Trauma and Attachment Support Group is conducted in partnership with the Foster and Kinship Care Education Program for Santa Barbara County. We provide support for parents with children with challenging behaviors.Please call to find out when then next meeting is being held!</p>
            </div>
        </div>
    )
}