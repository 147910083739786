import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Image } from 'antd';

import { Banner } from '../common/index';
import { NavigationItemsList, NavigationItem } from '../../utils';

import { baseBreadcrumbs } from './index';
import './OtherPrograms.css';

export const OtherPrograms = () => {
    const otherProgramsItems = useMemo(
        () => (NavigationItemsList.find((item) => item.item.key === 'other_programs'))?.subItems || [],
        [NavigationItemsList]);

    const renderNavigationItem = (navigationItem: NavigationItem) => {
        const { label, path, metadata } = navigationItem;
        if (!(metadata && metadata.description && metadata.image)) return;

        const description = Array.isArray(metadata.description) ? metadata.description[0] : metadata.description;
        let displayed = description;
        if (displayed.length > 139) {
            displayed = description.substring(0, 139);
            displayed = `${displayed.substring(0, Math.min(displayed.length, displayed.lastIndexOf(" ")))}...`
        }

        return (
            <Col md={12} sm={24}>
                <Link to={path}>
                    <Card hoverable cover={<Image src={metadata.image} preview={false} />}>
                        <Card.Meta title={label} description={displayed} />
                    </Card>
                </Link>
            </Col>
        )
    }
    const renderNavigationItems = (navigationItemsList: NavigationItem[]) => (
            <Row gutter={[{ xs: 8, sm: 16, md: 24}, { xs: 8, sm: 16, md: 24}]}>
                {navigationItemsList.map((item) => renderNavigationItem(item))}
            </Row>
        );

    return (
        <div id="other-programs">
            <Banner title='Other Programs' breadcrumbs={baseBreadcrumbs}/>
            <div className="cards-container">
                { renderNavigationItems(otherProgramsItems) }
            </div>
        </div>
    )
}