import React from 'react';
import { Link } from 'react-router-dom';

import { Banner } from '../../common/Banner/Banner';

import { baseBreadcrumbs } from '../index';
import './Eureka.css';

export const Eureka = () => {
    
    return (
        <div id="eureka">
            <Banner title={"Eureka"} breadcrumbs={[...baseBreadcrumbs, { name: "Eureka", path: "/eureka" }]}/>
            <div className="title-container"><h3>Providing individual and “intensive” services for the out of the area families.</h3></div>
        </div>
    )
}