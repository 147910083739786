import React, { useMemo } from 'react';
import { Link } from 'react-router-dom'

import { Banner } from '../common/Banner/Banner';

import { NavigationItemsList, NavigationItem } from '../../utils';

import { baseBreadcrumbs } from './index';
import './Locations.css';

export const Locations = () => {
    const locationsItems = useMemo(
        () => (NavigationItemsList.find((item) => item.item.key === 'locations'))?.subItems || [],
        [NavigationItemsList]);

    const renderNavigationItem = (navigationItem: NavigationItem) => {
        const { key, label, path } = navigationItem;
        return (
                <div className="location-container" key={key}>
                    <Link to={path}><h2 className="location">{label} {label.toLowerCase() === 'eureka' ? <span style={{ color: '#111111', fontWeight: 'lighter'}}>(coming soon)</span> : ''}</h2></Link>
                </div>
            )
    }
    const renderNavigationItems = (navigationItemsList: NavigationItem[]) => navigationItemsList.map((item) => renderNavigationItem(item));

    return (
        <div id="locations">
            <Banner title={"Locations"} breadcrumbs={baseBreadcrumbs} />
            <div className="locations-container">
                {renderNavigationItems(locationsItems.slice(0, locationsItems.length))}
            </div>
        </div>
    )
}