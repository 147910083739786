import React from 'react';

import './SiteButton.css';

interface SiteButtonProps {
    url: string;
    label: string;
    sameTab?: boolean;
}

export const SiteButton = (props: SiteButtonProps) => {
    const { url, label, sameTab } = props;

    if (sameTab) return (
        <a className="site-button" href={url}>
            <div>{label}</div>
        </a>
    )
    return (
        <a className="site-button" href={url} target="_blank" rel="noreferrer">
            <div>{label}</div>
        </a>
    );
}