import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'antd';

import { Banner, SiteButton } from '../../common';

import { baseBreadcrumbs } from '../index';

import first5_0 from '../../../static/img/other-programs/F5_Hand_Pantone.png';
import first5_1 from '../../../static/img/other-programs/F5_SLOlogo_Pantone-1024x248.png';
import './First5ResilientFamiliesGrant.css';


export const First5ResilientFamiliesGrant = () => {
    
    return (
        <div id="first-5-resilient-families-grant">
            <Banner title={"First 5 Reslient Families Grant"} breadcrumbs={[...baseBreadcrumbs, { name: "First 5 Reslient Families Grant", path: "/other_programs/first_5_resilient_families_grant" }]}/>
            <div className="image-container">
                <Image src={first5_0}/>
                <a href="https://www.first5slo.org/" target="_blank" rel="noreferrer">
                    <Image src={first5_1} preview={false}/>
                </a>
            </div>
            <div className="text-container">
                <h2>MP HEALTH</h2>
                <p>The funding we have received from First 5 San Luis Obispo County is provided by Proposition 10. First 5 SLO County advocates for and allocates funding to programs in SLO County that improve the lives of children from prenatal through age 5.</p>
                <p>This funding supports children prenatal through age of 5 and their families.  This recognition not only supports First 5 and its partners, but more importantly it recognizes the critical growth, development and learning that take place in early childhood.</p>
                <p>First 5 SLO County is a local independent government agency which works with its Commission and community partners to make sure children thrive in nurturing, respectful environments and enter school healthy and ready to learn. First 5 SLO County is a strong voice for children in this county, speaking up for needed programs, increased funding, enhanced awareness and understanding, and stronger partnerships that benefit children and families as a whole.</p>
                <p>As a grant recipient, we are a partner in our mission to support children and families.</p>
                <SiteButton label={"Agencies, Individuals & Organizations Inquiries and Referrals - COVID Resilient Families"} url={"https://forms.gle/6mSTjHq7oeN1tvSx5"} />
            </div>
        </div>
    )
}