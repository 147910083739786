import first5Image from '../static/img/other-programs/F5_Hand_Pantone-370x370.png';
import itTakesTwoToTalkImage from '../static/img/other-programs/ItTakesTwotoTalkLogo-370x370.jpeg';
import summerCampImage from '../static/img/other-programs/leo-rivas-wtxcaDIdOCM-unsplash-scaled-370x370.jpeg';
import moreThanWordsImage from '../static/img/other-programs/More-Than-Words-Logo-370x370.jpeg';

export const EMAIL = "mphealth@mariposasproject.com";
export const PHONE = "(805) 284-4826";
export const FAX = "(805) 823-4500";
export const ADDRESS = {
    street: "215 W Mission St",
    city: "Santa Barbara",
    state: "CA",
    zipcode: "93101"
}

export const ATASCADERO_PHONE = "(805)-637-0802";
export const ATASCADERO_EMAIL = "mph.atascadero@mariposasproject.com";
export const CAMP_MARIPOSAS_EMAIL = "camp@mariposasproject.com";

export const EMPLOYMENT_APPLICATION_LINK = "https://pdf.ac/AuriF";
export const ADMISSION_INTAKE_AND_CONSENT_FORM_URL = "https://pdf.ac/NFNsc";
export const ADMISSION_INTAKE_AND_CONSENT_FORM_ES_URL = "https://pdf.ac/EdDAd";

export interface NavigationItem {
    key: string;
    label: string;
    path: string;
    metadata?: {
        description?: string | string[];
        image?: string; // Parent navigation humbnail
    };
}

export interface NavigationListItem {
    item: NavigationItem;
    subItems?: NavigationItem[];
}

export const NavigationItemsList: NavigationListItem[] = [
    {
        item: { key: "about_us", path: "/about_us", label: "About Us" }, 
        subItems: [
            { key: "mission_statement", path: "/about_us/mission_statement", label: "Mission Statement", },
            { key: "our_staff", path: "/about_us/our_staff", label: "Our Staff", },
            // { key: "activity", path: "/about_us/activity", label: "Activity", }
        ]
    },
    {
        item: { key: "locations", path: "/locations", label: "Locations" },
        subItems: [
            { key: "santa_barbara", path: "/locations/santa_barbara", label: "Santa Barbara", },
            { key: "atascadero_clinic", path: "/locations/atascadero_clinic", label: "Atascadero Clinic", },
            { key: "del_norte_developmental_center", path: "/locations/del_norte_developmental_center", label: "Del Norte Developmental Center", },
            { key: "eureka", path: "/locations/eureka", label: "Eureka", },
            // { key: "mariposas_argentina", path: "/locations/mariposas_argentina", label: "Mariposas Argentina", },
        ]
    },
    {
        item: { key: "therapy_services", path: "/therapy_services", label: "Therapy Services" },
        subItems: [
            {
                key: "insurance_and_rates",
                path: "/therapy_services/insurance_and_rates",
                label: "Insurance and Rates", 
                metadata: {
                    description: [
                        'It\'s important for you to check with your insurance carrier to find out about the benefits offered as many private insurance carriers (PPO\'s and HMO\'s) cover speech and occupational therapy. Insurance plans vary widely across companies.',
                        'We are in-network providers for several private insurance companies including Blue Shield, Cigna, Optum, American Specialty, CenCal and Holman Group. If you do not see your insurance carrier, please contact us.',
                        'We are happy to discuss insurance coverage and our service rates with you for your child\'s evaluations and therapy.'
                    ]
                }
            },
            {
                key: "counseling",
                path: "/therapy_services/counseling",
                label: "Counseling", 
                metadata: {
                    description: [
                        'MP Health Counseling offers a variety of mental health services to individuals, couples and children. We also offer parent groups for children diagnosed with Autism Spectrum Disorder. We address numerous issues such as anxiety, depression, grief and loss, PTSD, attachment disorders and substance abuse. Please call our office if you have any question regarding counseling services (We also offer bilingual services).',
                    ]
                }
            },
            {
                key: "occupational_therapy",
                path: "/therapy_services/occupational_therapy",
                label: "Occupational Therapy", 
                metadata: {
                    description: [
                        'All of our OT evaluations and therapy services are conducted in our spacious and fun sensory gym at our clinic where our therapists focus on a variety of abilities for children such as feeding, sensory processing and regulation, handwriting (Handwriting Without Tears), balance, motor planning and activities of daily living.',
                    ]
                }
            },
            {
                key: "physical_therapy",
                path: "/therapy_services/physical_therapy",
                label: "Physical Therapy", 
                metadata: {
                    description: [
                        'The Trauma and Attachment Support Group is conducted in partnership with the Foster and Kinship Care Education Program for Santa Barbara County. We provide support for parents with children with challenging behaviors.Please call to find out when then next meeting is being held!',
                    ]
                }
            },
            {
                key: "private_school",
                path: "/therapy_services/private_school",
                label: "Private School", 
                metadata: {
                    description: [
                        'MP Health provides on-site speech therapy and occupational therapy services for our private school community in the Santa Barbara area. This service is for children who do not have regular access to their public school programs or for schools who wish to have their own on-site therapy services. Here, we conduct all speech therapy and occupational therapy services on site for preschool to school age children who require screenings, evaluations and possible weekly therapy services. We also offer handwriting therapy services (screenings, evaluations, therapy and groups) on-site as well. For more information, please contact our director for more information and how to set up a program at your school.',
                    ]
                }
            },
            {
                key: "speech_language_pathology",
                path: "/therapy_services/speech_language_pathology",
                label: "Speech-Language Pathology", 
                metadata: {
                    description: [
                        'We offer screenings, evaluations and therapy services for school age and young adult clients who present with a variety of articulation and language challenges. Our staff specializes in several areas of communication disorders such as apraxia, dysfluency, phonology, phonemic awareness, central auditory processing disorders and language delays.',
                        'We are pleased to offer Hanen Parent Training programs to address the needs of families with children from birth to age five.” And then: These programs are presented by trained and certified speech-language pathologists, who use the Hanen programs well-researched intervention strategies to teach parents how to use natural interactions to support language development.'
                    ]
                }
            },
            {
                key: "trauma_and_attachment",
                path: "/therapy_services/trauma_and_attachment",
                label: "Trauma and Attachment", 
                metadata: {
                    description: [
                        'The Trauma and Attachment Support Group is conducted in partnership with the Foster and Kinship Care Education Program for Santa Barbara County. We provide support for parents with children with challenging behaviors.Please call to find out when then next meeting is being held!',
                    ]
                }
            },
        ]
    },
    {
        item: { key: "other_programs", path: "/other_programs", label: "Other Programs" },
        subItems: [
            {
                key: "summer_camp",
                path: "/other_programs/summer_camp",
                label: "Summer Camp",
                metadata: {
                    description: 'A summer camp program geared for young and school-aged children with speech and/or occupational therapy needs. Our summer camp program offers a unique perspective on helping children with specific speech, occupational, movement and sensory needs. Camp Mariposas creates opportunities for young children to engage in fun activities that enhance and facilitate their communication and sensory development. With licensed therapists, Camp Mariposas will ensure that every day of camp will foster the speech, language, social skill building and sensory/movement will be put into practice.',
                    image: summerCampImage,
                
                }
            },
            {
                key: "first_5_resilient_families_grant",
                path: "/other_programs/first_5_resilient_families_grant",
                label: "First 5 Resilient Families Grant",
                metadata: {
                    description: 'The funding we have received from First 5 San Luis Obispo County is provided by Proposition 10. First 5 SLO County advocates for and allocates funding to programs in SLO County that improve the lives of children from prenatal through age 5.',
                    image: first5Image,
                
                }
            },
            {
                key: "it_takes_two_to_talk",
                path: "/other_programs/it_takes_two_to_talk",
                label: "It Takes Two to Talk",
                metadata: {
                    description: 'The Hanen Program for Parents of children with Language Delays: It Takes Two to Talk (ITTT) is designed specifically for parents of young children birth to age 5 who have been identified as having a language delay.',
                    image: itTakesTwoToTalkImage,
                
                }
            },
            {
                key: "more_than_words",
                path: "/other_programs/more_than_words",
                label: "More than Words",
                metadata: {
                    description: 'The Hanen Program for Parents of children with Language Delays: More Than Words (MTW) is designed specifically for parents of children ages 5 and under on the autism spectrum and with other social communication difficulties.',
                    image: moreThanWordsImage,
                
                }
            }
        ]
    },
    {
        item: { key: "start_child_therapy", path: "/start_child_therapy", label: "Start Child Therapy" },
    },
    {
        item: { key: "contact_us", path: "/contact_us", label: "Contact Us" },
        subItems: [
            { key: "employment", path: "/contact_us/employment", label: "Employment", },
        ]
    },
]

export const isEven = (x: number) => x % 2 == 0;