import React from 'react';
import { Image } from 'antd';
import { FacebookFilled, InstagramOutlined, DoubleRightOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom'

import { PHONE, ADDRESS, NavigationItemsList, NavigationListItem } from '../../utils';

import './Footer.css';
import logo from '../../static/img/MPHealthHeaderLogo.png';

const { street, city, state, zipcode } = ADDRESS;

export const Footer = () => {

    const renderNavigationItem = (navigationItem: NavigationListItem) => {
        const { item, subItems } = navigationItem;
        return subItems && subItems.length ? 
            (
                <div className="navigation-submenu-container" key={item.key}>
                    <Link to={item.path} key={item.key}><DoubleRightOutlined style={{ color: '#af3253'}}/><span className="list-header"> {item.label}</span></Link>
                    <ul>
                        {subItems.map((subItem) => (<li key={subItem.key}><Link to={subItem.path}>{subItem.label}</Link></li>))}                    
                    </ul>
                </div>
            ) :
            (
                <div className="navigation-submenu-container" key={item.key}>
                    <Link to={item.path}><DoubleRightOutlined style={{ color: '#af3253'}}/><span className="list-header"> {item.label}</span></Link>
                </div>
            )
    }
    const renderNavigationItems = (navigationItemsList: NavigationListItem[]) => navigationItemsList.map((item) => renderNavigationItem(item));

    return (
        <div id="footer">
            <div className="navigation-container">
                {renderNavigationItems(NavigationItemsList)}
            </div>
            <div className="contact-container">
                <Link to="/">
                    <Image preview={false} src={logo}/>
                </Link>
                <div className="info">
                    <div className="address">
                        <div><span>{street}</span></div>
                        <div><span>{`${city}, ${state} ${zipcode}`}</span></div>
                    </div>
                    <div className="phone">Phone: <a href={`tel:${PHONE}`}>{PHONE}</a></div>
                </div>
                <div className="social-media">
                    <ul>
                        <li><a href="" target="_blank"><FacebookFilled width={100} height={100}/></a></li>
                        <li><a href="" target="_blank"><InstagramOutlined width={100} height={100}/></a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}