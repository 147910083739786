import React from 'react';

import { Banner } from '../../common/index';

import { baseBreadcrumbs } from '../index';
import './PrivateSchool.css';

export const PrivateSchool = () => {
    return (
        <div id="private-school">
            <Banner title='Private School' breadcrumbs={[...baseBreadcrumbs, { name: 'Private School', path: '/therapy_services/private_school'}]}/>
            <div className="text-container">
                <p>MP Health provides on-site speech therapy and occupational therapy services for our private school community in the Santa Barbara area. This service is for children who do not have regular access to their public school programs or for schools who wish to have their own on-site therapy services. Here, we conduct all speech therapy and occupational therapy services on site for preschool to school age children who require screenings, evaluations and possible weekly therapy services. We also offer handwriting therapy services (screenings, evaluations, therapy and groups) on-site as well. For more information, please <a style={{ color: '#f7aa58'}} href="mailto:mph@mariposasproject.com">contact our director</a> for more information and how to set up a program at your school.</p>
            </div>
        </div>
    )
}