import React from 'react';
import { Routes, Route } from "react-router-dom";

import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';

import { Home } from './components/Home/Home';


import { 
  Locations,
  SantaBarbara,
  AtascaderoClinic,
  Eureka,
  DelNorteDevelopmentalCenter
} from './components/Locations';
import {
  AboutUs,
  Activity,
  MissionStatement,
  OurStaff
} from './components/AboutUs';
import {
  TherapyServices,
  Counseling,
  InsuranceAndRates,
  PhysicalTherapy,
  PrivateSchool,
  OccupationalTherapy,
  SpeechLanguagePathology,
  TraumaAndAttachmentSupportGroup
} from './components/TherapyServices';
import {
  OtherPrograms,
  SummerCamp,
  ItTakesTwoToTalk,
  MoreThanWords,
  First5ResilientFamiliesGrant,
} from './components/OtherPrograms';
import {
  StartChildTherapy
} from './components/StartChildTherapy';
import {
  ContactUs,
  Employment
} from './components/ContactUs';

import './App.css';
import 'antd/dist/antd.css';

function App() {
  return (
    <div className="App">
      <Header />
      <div id="content">
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="index.html" element={<Home />}/>
            <Route path="about_us">
              <Route index element={<AboutUs />}/>
              <Route path="mission_statement" element={<MissionStatement />} />
              <Route path="our_staff" element={<OurStaff />} />
              <Route path="activity" element={<Activity />} />
            </Route> 
            <Route path="locations">
              <Route index element={<Locations />} />
              <Route path="santa_barbara" element={<SantaBarbara />} />
              <Route path="atascadero_clinic" element={<AtascaderoClinic />} />
              <Route path="del_norte_developmental_center" element={<DelNorteDevelopmentalCenter />} />
              <Route path="eureka" element={<Eureka />} />
            </Route>
            <Route path="therapy_services">
              <Route index element={<TherapyServices />}/>
              <Route path="insurance_and_rates" element={<InsuranceAndRates />}/>
              <Route path="counseling" element={<Counseling />}/>
              <Route path="occupational_therapy" element={<OccupationalTherapy />}/>
              <Route path="physical_therapy" element={<PhysicalTherapy />}/>
              <Route path="private_school" element={<PrivateSchool />}/>
              <Route path="speech_language_pathology" element={<SpeechLanguagePathology />}/>
              <Route path="trauma_and_attachment" element={<TraumaAndAttachmentSupportGroup />}/>
            </Route> 
            <Route path="other_programs">
              <Route index element={<OtherPrograms />}/>
              <Route path="summer_camp" element={<SummerCamp />}/>
              <Route path="first_5_resilient_families_grant" element={<First5ResilientFamiliesGrant />}/>
              <Route path="it_takes_two_to_talk" element={<ItTakesTwoToTalk />}/>
              <Route path="more_than_words" element={<MoreThanWords />}/>
            </Route> 
            <Route path="start_child_therapy">
              <Route index element={<StartChildTherapy />}/>
            </Route> 
            <Route path="contact_us">
              <Route index element={<ContactUs />}/>
              <Route path="employment" element={<Employment />} />
            </Route> 
          </Route>
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
