import React from 'react';

import './Activity.css';

export const Activity = () => {
    return (
        <div id="activity">
            <div className="navigation-container">
                
            </div>
            <div className="contact-container">
            </div>
        </div>
    )
}