import React from 'react';

import { Banner } from '../../common/index';

import { baseBreadcrumbs } from '../index';
import './MissionStatement.css';

export const MissionStatement = () => {
    return (
        <div id="mission-statement">
            <Banner title='Mission Statement' breadcrumbs={[...baseBreadcrumbs, { name: 'Mission Statement', path: '/about_us/mission_statement'}]}/>
            <div className="text-container">
                <p>MP Health clinic offers unique speech-language pathology and occupational therapy services that focus on children and their families. We recognize each family as the true context of intervention and progress and will work to individualize your child&apos;s therapy program to meet your needs. We encourage parent participation in evaluations and therapy and incorporate parent training in our therapies to support child progress. Our highly trained and experienced staff will readily adapt to your family&apos;s needs.</p>
                <p>In support of parent involvement, MP Health offers evidence-based Hanen parent training programs to support speech and language development. Our Hanen program is designed to support parents to provide responsive interaction support during typical daily routines and can easily be incorporated into your day. Parent teaching can occur during your therapy sessions.</p>
            </div>
        </div>
    )
}