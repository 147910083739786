import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'antd';

import { Banner } from '../../common/Banner/Banner';
import { SiteButton } from '../../common/SiteButton/SiteButton';

import { baseBreadcrumbs } from '../index';

import './AtascaderoClinic.css';
import atascaderoClinic from '../../../static/img/locations/AtascaderoClinic.jpeg';

export const AtascaderoClinic = () => {
    
    return (
        <div id="atascadero-clinic">
            <Banner title={"Atascadero Clinic"} breadcrumbs={[...baseBreadcrumbs, { name: "Atascadero Clinic", path: "/atascadero_clinic" }]}/>
            <div className="image-container">
                <SiteButton url={"https://www.littlewingsofatascadero.com/"} label={"Inclusion Preschool"} />  
                <Image src={atascaderoClinic}/>
            </div>
        </div>
    )
}