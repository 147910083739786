import React from 'react';

import { Banner } from '../../common/index';

import { baseBreadcrumbs } from '../index';
import './PhysicalTherapy.css';

export const PhysicalTherapy = () => {
    return (
        <div id="physical-therapy">
            <Banner title='Physical Therapy' breadcrumbs={[...baseBreadcrumbs, { name: 'Physical Therapy', path: '/therapy_services/trauma_and_attachment_support_group'}]}/>
            <div className="text-container">
                <p>All physical therapy evaluations and treatment services are conducted at our clinic in our large sensory gym. Here, we focus on mobility training, coordination, mobility, strength and equipment adaptations.</p>
            </div>
        </div>
    )
}