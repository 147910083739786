import React from 'react';

import { Banner } from '../../common/index';

import { baseBreadcrumbs } from '../index';
import './OccupationalTherapy.css';

export const OccupationalTherapy = () => {
    return (
        <div id="occupational-therapy">
            <Banner title='Occupational Therapy' breadcrumbs={[...baseBreadcrumbs, { name: 'Occupational Therapy', path: '/therapy_services/trauma_and_attachment_support_group'}]}/>
            <div className="text-container">
                <p>All of our OT evaluations and therapy services are conducted in our spacious and fun sensory gym at our clinic where our therapists focus on a variety of abilities for children such as feeding, sensory processing and regulation, handwriting (Handwriting Without Tears), balance, motor planning and activities of daily living.</p>
            </div>
        </div>
    )
}