import React from 'react';

import { Banner } from '../../common/index';

import { baseBreadcrumbs } from '../index';
import './InsuranceAndRates.css';

export const InsuranceAndRates = () => {
    return (
        <div id="insurance-and-rates">
            <Banner title='Insurance and Rates' breadcrumbs={[...baseBreadcrumbs, { name: 'Insurance and Rates', path: '/therapy_services/insurance_and_rates'}]}/>
            <div className="text-container">
                <p>It&apos;s important for you to check with your insurance carrier to find out about the benefits offered as many private insurance carriers (PPO&apos;s and HMO&apos;s) cover speech and occupational therapy. Insurance plans vary widely across companies.</p>
                <p>We are in-network providers for several private insurance companies including Blue Shield, Cigna, Optum, American Specialty, and CenCal. If you do not see your insurance carrier, please contact us.</p>
                <p>We are happy to discuss insurance coverage and our service rates with you for your child&apos;s evaluations and therapy.</p>
            </div>
        </div>
    )
}