import React from 'react';
import { Link } from 'react-router-dom';

import { isEven } from '../../../utils';

import './Banner.css';
import background from '../../../static/img/Banner-Background.jpeg';

interface Breadcrumb {
    name: string;
    path: string;
}

interface BannerProps {
    title: string;
    breadcrumbs: Breadcrumb[];
}
export const Banner = (props: BannerProps) => {
    const { title, breadcrumbs } = props;

    const renderBreadcrumbs = () => {
        return <div className="breadcrumbs-list-container">
            {breadcrumbs.map((breadcrumb, index) => {
                const isLast = index === breadcrumbs.length - 1;
                return isLast ? 
                    <span className="breadcrumb current" key={breadcrumb.name}>{breadcrumb.name}</span> :
                    <span className="breadcrumb" key={breadcrumb.name}>
                        <Link to={breadcrumb.path}>{breadcrumb.name}</Link>
                        <span style={{ paddingLeft: '0.5em', paddingRight: '0.5em' }}>
                            /
                        </span>
                    </span>
            }
            )}
        </div>
    }

    return (
        <div className="banner" style={{ backgroundImage: `url(${background})` }}>
            <div className="title-container"><h1>{title}</h1></div>
            <div className="breadcrumbs-container">{renderBreadcrumbs()}</div>
        </div>
    )
}