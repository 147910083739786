import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'antd';

import { Banner } from '../../common/Banner/Banner';
import { SiteButton } from '../../common/SiteButton/SiteButton';

import { baseBreadcrumbs } from '../index';

import './DelNorteDevelopmentalCenter.css';
import delNorteDevelopmentalCenter0 from '../../../static/img/locations/DelNorteDevelopmentalCenter_0.png';
import delNorteDevelopmentalCenter1 from '../../../static/img/locations/DelNorteDevelopmentalCenter_1.png';

export const DelNorteDevelopmentalCenter = () => {
    
    return (
        <div id="del-norte-developmental-center">
            <Banner title={"Del Norte Developmental Center"} breadcrumbs={[...baseBreadcrumbs, { name: "Del Norte Developmental Center", path: "/del_norte_developmental_center" }]}/>
            <div className="image-container">
                <Image src={delNorteDevelopmentalCenter0}/>
                <Image src={delNorteDevelopmentalCenter1}/>
            </div>
            <div className="text-container">
                <p>Address: 1041 5th Street, Crescent City, CA</p>
                <p>Email: <a href="mailto:corym.mariposas@medsecuremail.com">corym.mariposas@medsecuremail.com</a></p>
                <SiteButton url={"https://www.littlewingsofatascadero.com/"} label={"Inclusion Preschool"} />
            </div>
        </div>
    )
}