import React from 'react';

import { Banner } from '../../common/index';

import { baseBreadcrumbs } from '../index';
import './OurStaff.css';

export const OurStaff = () => {
    return (
        <div id="our-staff">
            <Banner title='Our Staff' breadcrumbs={[...baseBreadcrumbs, { name: 'Our Staff', path: '/about_us/our_staff'}]}/>
            <div className="text-container">
                <p>Our multi-disciplinary team of therapists works with and provides treatment to a wide variety of children as each of our therapists has different areas of specialty, certifications and advanced training.</p>
                <p>We see children with a variety of conditions and diagnoses that include, but are not limited to treatment for:</p>
                <ul>
                    <li>Neurological Conditions</li>
                    <li>Genetic Disorders</li>
                    <li>Autism Spectrum Disorder</li>
                    <li>Cerebral Palsy</li>
                    <li>Down Syndrome</li>
                    <li>Sensory Integration Disorder</li>
                    <li>Feeding Disorders</li>
                    <li>Learning Disabilities</li>
                    <li>Handwriting Difficulties</li>
                </ul>
                <p>All of our services for MP Health are provided at our facilities where we have a large multi-purpose/multi-sensory gym with a variety of equipment and materials. We also have a comfortable lounge area for both our staff and families to wait while the children are provided their therapy services.</p>
                <h3 className="covid-alert">MP Health is currently following all guidelines and procedures for COVID-19 and currently providing services virtually.</h3>
            </div>
        </div>
    )
}