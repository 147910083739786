import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'antd';

import { PHONE, ATASCADERO_PHONE, EMAIL, CAMP_MARIPOSAS_EMAIL, ATASCADERO_EMAIL } from '../../../utils/index';

import { Banner } from '../../common/Banner/Banner';

import { baseBreadcrumbs } from '../index';

import summerCamp from '../../../static/img/other-programs/leo-rivas-wtxcaDIdOCM-unsplash-scaled-1170x659.jpeg';
import './SummerCamp.css';

export const SummerCamp = () => {
    
    return (
        <div id="summer-camp">
            <Banner title={"Summer Camp"} breadcrumbs={[...baseBreadcrumbs, { name: "Summer Camp", path: "/other_programs/summer_camp" }]}/>
            <div className="video-container">
                <div className="video-wrapper">
                    <iframe width={"100%"} height="563" src="https://www.youtube.com/embed/6Kd3-hgw7q8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>            
            <div className="text-container">
                <h2>Welcome to Camp Mariposas!</h2>
                <p>A summer camp program geared for young and school-aged children with speech and/or occupational therapy needs. Our summer camp program offers a unique perspective on helping children with specific speech, occupational, movement and sensory needs. Camp Mariposas creates opportunities for young children to engage in fun activities that enhance and facilitate their communication and sensory development. With licensed therapists, Camp Mariposas will ensure that every day of camp will foster the speech, language, social skill building and sensory/movement will be put into practice.</p>
            </div>
            <div className="text-container" style={{ paddingTop: 0 }}>
                <div>
                    <h2>Focus of Camp</h2>
                    <p>Led by Speech/Language and Occupational Therapists, Camp Mariposas offers expertise and focus in the following areas:</p>
                    <ul>
                        <li>Speech &amp; Articulation skills (ex., apraxia, phonology)</li>
                        <li>Social Communication and Interaction Skills</li>
                        <li>Receptive &amp; Expressive Language Skills (vocabulary, directions, grammar, sentence length, questions)</li>
                        <li>Self-regulation and sensory processing</li>
                        <li>Visual-spatial processing</li>
                        <li>Motor Planning &amp; Movement</li>
                        <li>Play skills</li>
                        <li>Parent Education &amp; Consultation</li>
                    </ul>
                </div>
                <div>
                    <h2>Ages &amp; Groups</h2>
                    <p><span style={{ fontWeight: 'bold', fontStyle: "italic" }}>Camp Mariposas</span> is geared for young and school-aged child between the ages of 3-12. All children will be grouped according to both age and developmental needs.</p>
                </div>
                <div>
                    <h2>Camp Sessions</h2>
                    <p>There are two sessions being offered for 3-week intervals during the Summer Months. You can choose for your child to attend one or two sessions. This year&apos;s 2023 Camp Mariposa&apos;s schedule is as follows:</p>
                    <ul style={{ listStyleType: 'none' }}>
                        <li style={{ fontWeight: 'bold', fontStyle: "italic", fontSize: '1.25em' }}>Session 1: June 19th - July 7th</li>
                        <li style={{ fontWeight: 'bold', fontStyle: "italic", fontSize: '1.25em' }}>Session 2: July 10th - July 28th</li>
                        <li style={{ fontWeight: 'bold', fontStyle: "italic", fontSize: '1.25em' }}>Session 3: July 31st - August 18th</li>
                    </ul>
                </div>
                <div>
                    <h2>Locations</h2>
                    <ul style={{ listStyleType: 'none', textAlign: 'center' }}>
                        <li style={{ fontWeight: 'bold', fontSize: '1.25em', textAlign: 'left', width: '50%' }}>215 W. Mission St., Santa Barbara, CA 93101</li>
                        <li style={{ fontWeight: 'bold', fontSize: '1.25em', textAlign: 'left', width: '50%' }}>6895 Morro Road, Atascadero, CA 93422</li>
                        <li style={{ fontWeight: 'bold', fontSize: '1.25em', textAlign: 'left', width: '50%' }}>Santa Maria Location TBA.</li>
                    </ul>
                </div>
                <div>
                    <h2>Contact Information</h2>
                    <p style={{ textAlign: 'center', fontSize: '1.25em', marginTop: '1em' }}>
                        You may request an application via email <b>indicating the area for which you are intending to have a child attend</b><br />
                        <a style={{ textDecoration: 'underline', }} href={`mailto:${CAMP_MARIPOSAS_EMAIL}`}>{CAMP_MARIPOSAS_EMAIL}</a>
                    </p>
                    <p style={{ fontSize: '1.25em', marginTop: '1em' }}>If you have further questions regarding Camp Mariposas, please call our office.</p>
                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                        <div>
                            <h3 style={{ fontWeight: 'bold', fontSize: '1.5em' }}>Santa Barbara</h3>
                            <p style={{ marginTop: '1em' }}>
                                <a href={`tel:${PHONE}`}>{PHONE}</a>
                                <br />
                                <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
                            </p> 
                        </div>
                        <div>
                            <h3 style={{ fontWeight: 'bold', fontSize: '1.5em' }}>Atascadero/Santa Maria</h3>
                            <p style={{ marginTop: '1em' }}>
                                <a href={`tel:${ATASCADERO_PHONE}`}>{ATASCADERO_PHONE}</a>
                                <br />
                                <a href={`mailto:${ATASCADERO_EMAIL}`}>{ATASCADERO_EMAIL}</a>
                            </p> 
                        </div>
                    </div>
                </div>
                <div>
                    <h2>Camp Tuition</h2>
                    <p style={{ fontStyle: 'italic', fontSize: '1em', marginTop: '1em' }}>If you have CenCal, camp will be covered completely and will be free of charge. We will request a copy of your child&apos;s insurance card.</p>
                    <p style={{ fontStyle: 'italic', fontSize: '1em', marginTop: '1em' }}>Scholarship may be available</p>
                    <p style={{ fontStyle: 'italic' }}>**You must submit a recent report, or most recent IEP for your child.</p>
                </div>
                
            </div>
        </div>
    )
}