import React from 'react';

import { Banner } from '../common/index';

import { baseBreadcrumbs } from './index';
import './AboutUs.css';

export const AboutUs = () => {
    return (
        <div id="about-us">
            <Banner title='About Us' breadcrumbs={baseBreadcrumbs}/>
            <div className="text-container">
                <p>MP Health was founded by Silvia Wasjutin, Owner/Speech &amp; Language Pathologist on the premise that our community needed private, multidisciplinary pediatric therapy services for school age children. With passion, innovation, excellence and community outreach, MP Health is a one-of-a-kind therapy agency here in Santa Barbara.</p>
                <hr />
                <p>At the heart of MP Health is a wide variety of programs and services, a commitment to families and their children, as well as a staff of dedicated therapists and counselors to complete a comprehensive scope of services.</p>
            </div>
        </div>
    )
}