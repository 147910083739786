import React from 'react';

import { Banner } from '../common/index';
import { PHONE, FAX, EMAIL, ATASCADERO_PHONE, ATASCADERO_EMAIL } from '../../utils'
import { baseBreadcrumbs } from './index';
import './ContactUs.css';

export const ContactUs = () => {
    return (
        <div id="contact-us">
            <Banner title='Contact Us' breadcrumbs={baseBreadcrumbs}/>
            <div className='text-container'>
                <div>
                    <h1>MP Health of Santa Barbara</h1>
                    <hr/>
                    <h3>215 W. Mission St.<br />Santa Barbara, CA 93101</h3>
                    <h3>Phone: {PHONE}</h3>
                    <h3>Fax: {FAX}</h3>
                    <h3>Email: <a href={`mailto:mph@mariposasproject.com`}>mph@mariposasproject.com</a></h3>
                </div>
                <div>
                    <h1>MP Health of Atascadero</h1>
                    <hr/>
                    <h3>6895 Morro Road<br />Atascadero, CA 93422</h3>
                    <h3>Phone: {ATASCADERO_PHONE}</h3>
                    <h3>Email: <a href={`mailto:${ATASCADERO_EMAIL}`}>{ATASCADERO_EMAIL}</a></h3>
                    {/* <hr /> */}
                </div>
            </div>
            <hr style={{ width: '90%', opacity: '0.5' }}/>
            <div className='text-container'>
                <div>
                    <h3>Silvia Wasjutin</h3>
                    <p>Owner - MP Health Santa Barbara</p>

                    <h3>Elena Requena</h3>
                    <p>Office Manager - MP Health Santa Barbara</p>

                    <h3>Nancy Aviles</h3>
                    <p>Manager - MP Health Santa Barbara</p>

                    <h3>Eunice LaSanta</h3>
                    <p>Office Assistant - MP Health Santa Barbara</p>
                    <hr style={{ width: '15%', opacity: '0.5'}}/>
                </div>
            </div>
        </div>
    )
}