import React from 'react';
import { Image } from 'antd';
import { ReadOutlined } from '@ant-design/icons';

import { Carousel } from '../common';
import { NavigationItemsList } from '../../utils';
import bannerImage from '../../static/img/home/Banner-Background.jpeg';
import homeDescriptionImage from '../../static/img/home/annie-spratt-fBrGckWLQ0Q-unsplash.jpeg';
import funnyEyes from '../../static/img/home/edi-libedinsky-1bhp9zBPHVE-unsplash1.jpeg';
import './Home.css';
import { Link } from 'react-router-dom';

const { REACT_APP_ENV } = process.env;

const carouselItems = [
    {
        header: 'Helping People Gain Power in Their Life',
        buttonLabel: 'More Information',
        buttonUrl: `${REACT_APP_ENV === 'production' ? '' : '/demo-site'}/about_us`,
        backgroundImage: `url(${bannerImage})`
    },
    {
        header: 'We Can Do Small Things With Great Love',
        buttonLabel: 'More Information',
        buttonUrl: `${REACT_APP_ENV === 'production' ? '' : '/demo-site'}/about_us`,
        backgroundImage: `url(${homeDescriptionImage})`
    },
    {
        header: 'Improve Your Life',
        subHeader: 'Relate to each other from the best in you...',
        buttonLabel: 'More Information',
        buttonUrl: `${REACT_APP_ENV === 'production' ? '' : '/demo-site'}/about_us`,
        backgroundImage: `url(${funnyEyes})`
    }
];
const therapyServicesItems = NavigationItemsList.find((item) => item.item.key === 'therapy_services')?.subItems;

export const Home = () => {
    const renderServicesLinks = () => (
        <ul className="home-services-links-list">
            {therapyServicesItems?.map((therapyServicesItem) => {
                return (
                    <li key={therapyServicesItem.key}>
                        <Link to={therapyServicesItem.path}><ReadOutlined style={{ color: '#f7aa58' }}/>  {therapyServicesItem.label}</Link>
                    </li>
                )
            })}
        </ul>
    );
    return (
        <div id="home">
            <Carousel 
                items={carouselItems}
                dots={{ className: 'home-carousel-dots' }}
                autoplay
            />
            <div className="text-container description">
                <div>
                    <h2>Mariposas Health Project</h2>
                    <p>MP Health is a healthcare organization with a two-fold mission of providing both a multitude of therapy services to children who are 3+ years old. With passion, innovation, excellence and community outreach, MP Health is a one-of-a-kind therapy agency in Santa Barbara, Atascadero, Eureka and the Del Norte Developmental Center.</p>
                </div>
                <div className="description-image-wrapper">
                    <Image src={homeDescriptionImage} preview={false} />
                </div>
            </div>
            <div className="text-container our-services">
                <h2>Our Services</h2>
                <hr />
                <p>AT THE HEART OF MP HEALTH, IS A WIDE VARIETY OF PROGRAMS AND SERVICES, A COMMITMENT TO FAMILIES AND THEIR CHILDREN AS WELL AS, A STAFF OF DEDICATED THERAPISTS AND COUNSELORS.</p>
                {renderServicesLinks()}
            </div>
        </div>
    )
}