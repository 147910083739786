import React from 'react';

import { Banner } from '../../common/index';

import { baseBreadcrumbs } from '../index';
import './SpeechLanguagePathology.css';
import itTakesTwotoTalkPdf from '../../../static/docs/ITTT-parent-brochure-editable.pdf';
import moreThanWordsPdf from '../../../static/docs/MTW-parent-brochure-editable.pdf';
import seNecesitanDosPdf from '../../../static/docs/Se-Necesitan-Dos-para-Hablar.pdf';
import masQuePalabrasPdf from '../../../static/docs/Mas-que-Palabras.pdf';

export const SpeechLanguagePathology = () => {
    return (
        <div id="speech-language-pathology">
            <Banner title='Speech-Language Pathology' breadcrumbs={[...baseBreadcrumbs, { name: 'Speech-Language Pathology', path: '/therapy_services/speech_language_pathology'}]}/>
            <div className="text-container">
                <p>We offer screenings, evaluations and therapy services for school age and young adult clients who present with a variety of articulation and language challenges. Our staff specializes in several areas of communication disorders such as apraxia, dysfluency, phonology, phonemic awareness, central auditory processing disorders and language delays.</p>
                <p>We are pleased to offer Hanen Parent Training programs to address the needs of families with children from birth to age five.” And then: These programs are presented by trained and certified speech-language pathologists, who use the Hanen programs well-researched intervention strategies to teach parents how to use natural interactions to support language development.</p>
                <ul>
                    <li><a href={itTakesTwotoTalkPdf} target="_blank" rel="noreferrer">It Takes Two to Talk Parent Brochure</a></li>
                    <li><a href={moreThanWordsPdf} target="_blank" rel="noreferrer">More than Words Parent Brochure</a></li>
                    <li><a href={seNecesitanDosPdf} target="_blank" rel="noreferrer">Se Necesitan Dos para Hablar</a></li>
                    <li><a href={masQuePalabrasPdf} target="_blank" rel="noreferrer">Más que Palabras</a></li>
                </ul>
            </div>
        </div>
    )
}