import React from 'react';
import { Carousel as _Carousel } from 'antd';

import { SiteButton } from '../';
import './Carousel.css';

interface CarouselItems {
    header?: string;
    subHeader?: string;
    buttonLabel?: string;
    buttonUrl?: string;
    backgroundImage?: string;
}

interface CarouselProps {
    [key: string]: any;
    items: CarouselItems[];
}

export const Carousel = (props: CarouselProps) => {
    const { items } = props;

    const onChange = () => {

    }

    const renderItems = () => {
        return items.map((item) => 
                <div className="carousel-item" key={item.header}>
                    <div className="carousel-item-wrapper" style={{ backgroundImage: item.backgroundImage }}>
                        <div className="carousel-item-contents-wrapper">
                            <h2>{item.header}</h2>
                            <h3>{item.subHeader}</h3>
                            <SiteButton label={item.buttonLabel || 'More Information'} url={item.buttonUrl || '/'} sameTab />
                        </div>
                    </div>
                </div>
            
        );
    }

    return (
        <_Carousel afterChange={onChange} {...props}>
            {renderItems()}
        </_Carousel>
    );
}
