import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card} from 'antd';

import { Banner } from '../common/index';
import { NavigationItemsList, NavigationItem } from '../../utils';

import { baseBreadcrumbs } from './index';
import './TherapyServices.css';

export const TherapyServices = () => {
    const therapyServicesItems = useMemo(
        () => (NavigationItemsList.find((item) => item.item.key === 'therapy_services'))?.subItems || [],
        [NavigationItemsList]);

    const renderNavigationItem = (navigationItem: NavigationItem) => {
        const { key, label, path, metadata } = navigationItem;
        if (!(metadata && metadata.description)) return;

        const description = Array.isArray(metadata.description) ? metadata.description[0] : metadata.description;
        let displayed = description;
        if (displayed.length > 139) {
            displayed = description.substring(0, 139);
            displayed = `${displayed.substring(0, Math.min(displayed.length, displayed.lastIndexOf(" ")))}...`
        }

        return (
            <Col span={8}>
                <Link to={path}>
                    <Card hoverable title={label}>
                        {displayed}
                    </Card>
                </Link>
            </Col>
        )
    }
    const renderNavigationItems = (navigationItemsList: NavigationItem[]) => (
            <Row gutter={[{ xs: 8, sm: 16, md: 24}, { xs: 8, sm: 16, md: 24}]}>
                {navigationItemsList.map((item) => renderNavigationItem(item))}
            </Row>
        );

    return (
        <div id="therapy-services">
            <Banner title='Therapy Services' breadcrumbs={baseBreadcrumbs}/>
            <div className="text-container">
                <p style={{ marginBottom: '2em' }}>MP Health of Santa Barbara offers a full spectrum of pediatric therapy services, support groups and child/family counseling. Family participation in our therapy services is the cornerstone of our philosophy and a key component to ensure consistent success for all of our clients. We understand the important role that our children&apos;s families and caregivers play thus our viewpoint of providing a family-centered approach with all of our therapy services. Below, is our scope of services for children, ages 3 – young adults:</p>
            </div>
            <div className="cards-container">
                { renderNavigationItems(therapyServicesItems) }
            </div>
        </div>
    )
}