import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Image, Button } from 'antd';
import { HomeOutlined, MailOutlined, PhoneOutlined, DownOutlined, MenuOutlined, CloseOutlined } from '@ant-design/icons';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import { NavigationItemsList, NavigationListItem, PHONE, ADDRESS, EMAIL } from '../../utils';

import './Header.css';
import logo from '../../static/img/MPHealthHeaderLogo.png';

const { SubMenu } = Menu;
const { street, city, state, zipcode } = ADDRESS;

export const Header = () => {
    const [currentKey, setCurrentKey] = useState("home");
    const [collapsed, setCollapsed] = useState(true);

    const { height, width } = useWindowDimensions();
    const [isMobile, isTablet] = useMemo(() => [width <= 480, width < 1240 && width > 480], [width]);

    const handleClick = (e: any) => {
        setCollapsed(true);
        setCurrentKey(e.key);
    }

    const renderNavigationItem = (navigationItem: NavigationListItem) => {
        const { item, subItems } = navigationItem;
        return subItems && subItems.length ? 
            <SubMenu key={item.key} title={<Link to={item.path}>{item.label.toUpperCase()} {isMobile || isTablet ? '' : <DownOutlined />}</Link>}>
                {subItems.map((subItem) => (<Menu.Item key={subItem.key} style={{ background: '#313131'}}><Link to={subItem.path}>{subItem.label.toUpperCase()}</Link></Menu.Item>))}                    
            </SubMenu> :
            <Menu.Item key={item.key}><Link to={item.path}>{item.label.toUpperCase()}</Link></Menu.Item>
    }
    const renderNavigationItems = (navigationItemsList: NavigationListItem[]) => navigationItemsList.map((item) => renderNavigationItem(item));

    if (isMobile || isTablet)
        return (
            <div id="header">
                <Menu
                    onClick={handleClick}
                    selectedKeys={[]}
                    mode={"inline"}
                    theme={"dark"}
                    style={collapsed ? 
                        { opacity: 0, width: 0 } : 
                        { opacity: 1, width: isTablet ? '45vw' : '100vw' }
                    }
                    inlineCollapsed={collapsed}
                    className='mobile-menu'
                >
                    {renderNavigationItems(NavigationItemsList)}
                </Menu>
                    
                <div className="navigation-container">
                    <Button type="primary" onClick={() => setCollapsed(!collapsed)} style={{
                            position: 'absolute',
                            background: 'none',
                            border: 'none',
                            boxShadow: 'none',

                            ...(isTablet ? 
                                // { fontSize: '1.5em', left: 0, marginLeft: '2em', } : 
                                { fontSize: '1.5em', right: 0, marginRight: '2em', } : 
                                { fontSize: '1.75em', right: 0, marginRight: '0.5em' }
                            )
                        }}>
                        {collapsed ? <MenuOutlined /> : <CloseOutlined />}
                    </Button>
                    <Link to="/">
                        <Image preview={false} src={logo} width={isTablet ? '100%' : '75%'} height={isTablet ? 80 : 30}/>
                    </Link>
                </div>                    
            </div>
        )
    else
        return (
            <div id="header">
                <div className="contact-container">
                    <div className="address"><HomeOutlined /> <span>{`${street}, ${city}, ${state} ${zipcode}`}</span></div>
                    <div className="email-phone">
                        <div>
                            <MailOutlined /> <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
                        </div>
                        <div>
                            <PhoneOutlined /> <a href={`tel:${PHONE}`}>{PHONE}</a>
                        </div>
                    </div>
                </div>
                <div className="navigation-container">
                    <Link to="/">
                        <Image preview={false} src={logo} width={'100%'} height={80}/>
                    </Link>
                    <Menu onClick={handleClick} selectedKeys={[]} mode={"horizontal"} theme={"dark"}>
                        {renderNavigationItems(NavigationItemsList)}
                    </Menu>
                </div>            
            </div>
        );
}
